<template>
  
  <UiContentNotExists
    v-if="data_settings?.id != useAuth().getId()"
    />

  <div class="pb" v-else-if="data_settings?.id && !is_loading">
    <UiTabs 
      :tabs="tabs" 
      :acttab="active_tab"
      />    
    <br>
    <NuxtPage
      :data="data_settings"
      :user-id="data_settings.id"
      />
  </div>
</template>

<script setup> 

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const {$api, $tagsUtil, $popup, $ga} = useNuxtApp()

var link = ref(route.params['id']);
var active_tab = ref('profile');


const tabs = computed(() => {

  var items = [];

  items.push({
    id: 'profile',
    name: t('profile_menu_edit_profile'),
    link: data_settings.value.link + '/settings'
  });

  items.push({
    id: 'cookies',
    name: t('profile_menu_edit_profile_cookie_title'),
    link: data_settings.value.link + '/settings/cookies'
  });

  items.push({
    id: 'socials',
    // name: t('profile_menu_edit_profile_socials_title'),
    name: 'Socials',
    link: data_settings.value.link + '/settings/socials'
  });

  if(data_settings.value.cnt_bn){
    items.push({
      id: 'banned',
      name: t('profile_menu_edit_profile_blocked_title'),
      link: data_settings.value.link + '/settings/banned'
    });
  }

  items.push({
    id: 'delete',
    name: t('profile_menu_edit_profile_delete_title'),
    link: data_settings.value.link + '/settings/delete'
  });

  return items;
});
 


const loadData = async function(grower_id) {   
  const response = await $api.getGrowerByName(grower_id)  
  return response;  
}


const { pending: is_loading,  data: data_settings } = await useLazyAsyncData('data_settings', async () => await loadData(link.value))



// onMounted(() => {
//   if(link.value != useAuth().getName()){
//     router.push('/grower/' + useAuth().getName() + '/settings');
//     // useError({ statusCode: 404, message: 'Post not found' })
//   } 
// })



</script>

<style scoped>



</style>
